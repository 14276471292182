import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import audioHookPartner from 'src/assets/landing/audioHookPartner.png';
import katzDigitallogo from 'src/assets/landing/katzDigitallogo.webp';
import geminiXIIIPartner from 'src/assets/landing/geminiXIIIPartner.png';
import radioAmeriaPartner from 'src/assets/landing/radioAmeriaPartner.png';
import SiriusXMLogo from 'src/assets/landing/SiriusXMLogo.png';
import RocketCompaniesLogo from 'src/assets/landing/RocketCompaniesLogo.png';
import PublicisLogo from 'src/assets/landing/PublicisLogo.png';
import LightLogo from 'src/assets/landing/LightLogo.png';
import HorizonMediaLogo from 'src/assets/landing/HorizonMediaLogo.png';
import betterhelpLogo from 'src/assets/landing/betterhelpLogo.svg';
import Acast2022 from 'src/assets/landing/Acast2022.png';
import OMDTransparent from 'src/assets/landing/OMDTransparent.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      padding: '92px 0',
      background: '#EFEDEB',
    },
    mainContainer: {
      width: 'calc(100% - 8px)',
      margin: '0 4px',
      padding: '90px 16px',
      display: 'flex',
      flexDirection: 'column',
      background: '#E8E5E2',
      borderRadius: 2,
      gap: 60,
      overflow: 'hidden',

      '@media screen and (min-width: 768px)': {
        width: 'calc(100% - 32px)',
        margin: '0 16px',
        gap: 120,
        padding: '90px 100px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },

      '& > div': {
        flex: 1,
      },
    },
    title: {
      maxWidth: 250,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
      color: '#202226',
      margin: 0,

      '@media screen and (min-width: 560px)': {
        maxWidth: '100%',
        fontSize: 20,
        lineHeight: '40px',
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',
      margin: 0,

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
      },
    },
    partners: {
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      animation: '$scroll 30s linear infinite',
      '&:hover': {
        animationPlayState: 'paused',
      },

      '@media screen and (min-width: 768px)': {
        animation: 'none',
        justifyContent: 'center',
        flexWrap: 'wrap',
        columnGap: 30,
        rowGap: 40,
      },

      '& img': {
        transition: 'transform .25s linear',
        '&:hover': {
          transform: 'scale(1.1);',
        },
      },
    },
    '@keyframes scroll': {
      '100%': {
        transform: 'translateX(-1200px)',
      },
    },
  })
);

const BarometerPartners: FC = () => {
  const classes = useStyles();

  const partners = [
    {
      logo: audioHookPartner,
      link: 'https://www.audiohook.com/',
      alt: 'audioHookPartner',
      height: 30,
    },
    {
      logo: katzDigitallogo,
      link: 'https://katzdigital.com/',
      alt: 'katzDigitallogo',
      height: 60,
    },
    {
      logo: geminiXIIIPartner,
      link: 'https://gemini13media.com/',
      alt: 'geminiXIIIPartner',
      height: 60,
    },
    {
      logo: radioAmeriaPartner,
      link: 'https://www.radioamerica.com/',
      alt: 'radioAmeriaPartner',
      height: 55,
    },
    {
      logo: SiriusXMLogo,
      link: '/Users/webdev/Downloads/SiriusXMLogo.png',
      alt: 'SiriusXMLogo',
      height: 40,
    },
    {
      logo: RocketCompaniesLogo,
      link: 'https://rocketcompanies.com/',
      alt: 'RocketCompaniesLogo',
      height: 50,
    },
    {
      logo: PublicisLogo,
      link: 'https://www.publicis.com/',
      alt: 'PublicisLogo',
      height: 55,
    },
    {
      logo: LightLogo,
      link: 'https://soundstack.com/',
      alt: 'LightLogo',
      height: 30,
    },
    {
      logo: HorizonMediaLogo,
      link: 'https://www.horizonmedia.com/',
      alt: 'HorizonMediaLogo',
      height: 55,
    },
    {
      logo: betterhelpLogo,
      link: 'https://www.betterhelp.com/',
      alt: 'betterhelpLogo',
      height: 30,
    },
    {
      logo: Acast2022,
      link: 'https://www.acast.com/',
      alt: 'Acast2022',
      height: 45,
    },
    {
      logo: OMDTransparent,
      link: 'https://www.omnicomgroup.com/',
      alt: 'OMDTransparent',
      height: 45,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div>
          <p className={classes.title}>
            Trusted partnerships increase your ROI
          </p>
          <p className={classes.description}>
            Leverage your Barometer access across omnichannel partners and
            experience consistent transparency across digital channels
          </p>
        </div>
        <div className={classes.partners}>
          {partners.map((partner) => (
            <a
              key={partner.alt}
              href={partner.link}
              rel='noreferrer'
              target='_blank'
            >
              <img
                src={partner.logo}
                height={partner.height}
                alt={partner.alt}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BarometerPartners;
