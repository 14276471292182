import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';

import UnauthorizedContainWrapper from '../UnauthorizedContainWrapper';
import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/Button';

const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      height: '100%',
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      zIndex: 1,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        padding: '0 100px',
      },
    },
    title: {
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      color: '#202226',
      textTransform: 'uppercase',
      marginBottom: 50,
      marginTop: 40,

      '@media screen and (min-width: 768px)': {
        fontSize: 76,
        lineHeight: '96px',
      },
    },
    articles: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      padding: '20px 40px',
      rowGap: 48,
      columnGap: 20,
      marginBottom: 70,
      '@media screen and (min-width: 768px)': {
        fontSize: 76,
        lineHeight: '96px',
      },

      '& > div': {
        position: 'relative',
      },
    },
  })
);

const articles = [
  {
    date: new Date('2024-07-23'),
    title: 'Part 1: Inclusion by Design',
    link: 'https://medium.com/@tamara_56219/part-1-inclusion-by-design-b8214c81ca15',
    logo: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*u95sITr7HMnog_gj6D_tqw.png',
    type: 'medium',
    author: 'Tamara',
  },
  {
    date: new Date('2024-07-23'),
    title: 'The Inclusive Contextual Suitability Declarations',
    link: 'https://medium.com/@tamara_56219/the-inclusive-contextual-suitability-declarations-4be89ea4b50d',
    logo: '',
    type: 'medium',
    author: 'Tamara',
  },
  {
    date: new Date('7/18/2024'),
    title:
      'SoundStack’s Programmatic Marketplace To Offer Podcast Buyers Curated Ad Packs',
    link: 'https://www.insideradio.com/free/soundstack-s-programmatic-marketplace-to-offer-podcast-buyers-curated-ad-packs/article_b34d03fe-44cc-11ef-9bbb-23c8f80b02a4.html',
    logo: 'https://bloximages.newyork1.vip.townnews.com/insideradio.com/content/tncms/assets/v3/editorial/c/28/c28824f2-44cc-11ef-9668-8f79aec20b9c/6698b275189b2.image.jpg?resize=375%2C325',
    type: 'article',
    author: '',
  },
  {
    date: new Date('7/9/2024'),
    title: 'Barometer, Audiohook Launch Brand Suitability Framework',
    link: 'https://news.radio-online.com/articles/n45435/Barometer-Audiohook-Launch-Brand-Suitability-Framework',
    logo: 'https://images.radio-online.com/images/logos/BarometerAudiohookXL.png',
    type: 'article',
    author: '',
  },
  {
    date: new Date('7/9/2024'),
    title:
      'Barometer, Audiohook Debut More Inclusive Brand Safety Framework Built for Audio',
    link: 'https://www.insideradio.com/free/barometer-audiohook-debut-more-inclusive-brand-safety-framework-built-for-audio/article_f2d75d9e-3e87-11ef-92a2-935fb5dd1a87.html',
    logo: 'https://bloximages.newyork1.vip.townnews.com/insideradio.com/content/tncms/assets/v3/editorial/1/ee/1ee11c04-3e88-11ef-bd50-cba882fdb56c/668e2e4c723f2.image.jpg?resize=375%2C325',
    type: 'article',
    author: '',
  },
  {
    date: new Date('5/8/2024'),
    title:
      'Wondery Launches Wondery Ad Analytics to Improve Transparency, Performance and Measurement for Advertisers',
    link: 'https://podnews.net/press-release/wondery-ad-analytics',
    logo: '',
    type: 'article',
    author: '',
  },
  {
    date: new Date('1/22/2024'),
    title:
      'Black-Owned Podcast Platform Teams Up With Barometer Boosting Monetization For Black Creators',
    link: 'https://peopleofcolorintech.com/articles/black-owned-podcast-platform-teams-up-with-barometer-boosting-monetization-for-black-creators/',
    logo: 'https://peopleofcolorintech.com/wp-content/uploads/2024/01/alive-podcast-network-1080x635.jpeg',
    type: 'article',
    author: '',
  },
  {
    date: new Date('11/29/2023'),
    title:
      'Cumulus Podcast Network Partners With Barometer For Brand Safety Measurement',
    link: 'https://www.insideradio.com/free/cumulus-podcast-network-partners-with-barometer-for-brand-safety-measurement/article_3250d5da-8f02-11ee-93ab-576e4a342a75.html',
    logo: 'https://bloximages.newyork1.vip.townnews.com/insideradio.com/content/tncms/assets/v3/editorial/4/18/418cc874-8f02-11ee-84cc-2f955a0c0f02/6567b3c69aabf.image.jpg?resize=375%2C325',
    type: 'article',
    author: '',
  },
  {
    date: new Date('10/3/2023'),
    title: 'Barometer Brings AI-Powered Inclusivity to Drag Podcasts',
    link: 'https://radioink.com/2023/10/03/barometer-brings-ai-powered-inclusivity-to-drag-podcasts/',
    logo: 'https://radioink.com/wp-content/uploads/2023/05/Screen-Shot-2023-05-15-at-2.48.38-PM-e1684176578854.png',
    type: 'article',
    author: '',
  },
  {
    date: new Date('9/7/2023'),
    title:
      'SiriusXM creates a podcasting brand safety and suitability tool for advertisers',
    link: 'https://digiday.com/media-buying/sirius-xm-creates-a-podcasting-brand-safety-and-suitability-tool-for-advertisers/',
    logo: 'https://digiday.com/wp-content/uploads/sites/3/2027/01/podcast-advertising.jpeg?resize=1030,579',
    type: 'article',
    author: '',
  },
  {
    date: new Date('8/30/2023'),
    title:
      'HearstLab announces fundraising round with Barometer, a podcast measurement solution',
    link: 'https://mediamakersmeet.com/hearstlab-announces-fundraising-round-with-barometer-a-podcast-measurement-solution/',
    logo: 'https://mediamakersmeet.com/wp-content/uploads/2023/04/photo-1646242665072-90ec5247d523.jpeg',
    type: 'article',
    author: '',
  },
  {
    date: new Date('5/14/2024'),
    title:
      'Frequency partners Barometer for contextual targeting in podcast ads',
    link: 'https://podcastingtoday.co.uk/frequency-partners-barometer-for-contextual-targeting-in-podcast-ads/',
    logo: 'https://podcastingtoday.co.uk/wp-content/webp-express/webp-images/uploads/2024/05/Frequency-Barometer-Logos.jpg.webp',
    type: 'article',
    author: '',
  },
  {
    date: new Date('10/3/2023'),
    title:
      'MarTech Interview with Tamara Zubatiy, Co-founder and Chief Executive Officer of Barometer',
    link: 'https://www.martechcube.com/martech-interview-with-tamara-zubatiy/',
    logo: 'https://www.martechcube.com/wp-content/uploads/2023/10/Tamara-Zubatiy-interview-1024x576.jpg',
    type: 'article',
    author: '',
  },
  {
    date: new Date('4/11/2023'),
    title:
      'Katz Digital Partners With Barometer To Bring New Brand Insights To Podcast Advertising',
    link: 'https://www.insideradio.com/free/katz-digital-partners-with-barometer-to-bring-new-brand-insights-to-podcast-advertising/article_f012c256-d82b-11ed-84a8-13a04e5e9346.html',
    logo: 'https://bloximages.newyork1.vip.townnews.com/insideradio.com/content/tncms/assets/v3/editorial/0/27/027e7eb2-d82c-11ed-b6c5-df6dfa1101a3/6434f3edab6e5.image.jpg?resize=375%2C325',
    type: 'article',
    author: '',
  },
  {
    date: new Date('7/16/2024'),
    title: 'Barometer and SoundStack Partner To Enhance Audio Ad Marketplace',
    link: 'https://soundsprofitable.com/press-release/barometer-and-soundstack-partner-to-enhance-audio-ad-marketplace/',
    logo: 'https://soundsprofitable.com/wp-content/uploads/2024/07/Barometer-x-SoundStack-800x500-1-768x480.jpg',
    type: 'article',
    author: '',
  },
  {
    date: new Date('8/15/2024'),
    title:
      'Libsyn and Barometer Join Forces to Bolster Brand Safety Across Expansive Podcasting Marketplace',
    link: 'https://www.accesswire.com/901622/libsyn-and-barometer-join-forces-to-bolster-brand-safety-across-expansive-podcasting-marketplace#:~:text=(%22Libsyn%22)%2C%20the,extensive%20podcasting%20marketplace%20%2D%20providing%20continuous',
    logo: 'https://www.accesswire.com/imagelibrary/8cf33b8a-17b8-4eed-9b20-7d534161638f/901622/barometer-x-libsyn-logo-variation1.png',
    type: 'article',
    author: '',
  },
  {
    date: new Date('8/22/2024'),
    title:
      'Podcasters See Positives In GARM’s Demise, Especially For News-Driven Shows.',
    link: 'https://www.podcastnewsdaily.com/news/podcasters-see-positives-in-garm-s-demise-especially-for-news-driven-shows/article_f241de00-60a0-11ef-aefc-f758c22bd66d.html',
    logo: 'https://bloximages.newyork1.vip.townnews.com/podcastnewsdaily.com/content/tncms/assets/v3/editorial/5/ee/5ee47ae2-3958-11ef-8da1-17121811a1a0/66857abe9f078.image.jpg?resize=265%2C265',
    type: 'article',
    author: '',
  },
];

export default function Blog() {
  const classes = useStyles();
  const textClasses = useTextStyles();

  return (
    <UnauthorizedContainWrapper>
      <Box className={classes.mainContainer}>
        <Typography className={classes.title}>THE BAROMETER BEAT</Typography>
        <Box className={classes.articles}>
          {articles
            .sort((a, b) => b.date.getTime() - a.date.getTime())
            .map((article, index) => (
              <Box key={index}>
                <Box
                  style={{
                    padding: '4px',
                    borderRadius: 2,
                    background: 'white',
                    position: 'absolute',
                    top: 4,
                    left: 6,
                  }}
                >
                  <Typography className={textClasses.xxsRegular}>
                    {format(article.date, 'MM/dd/yy')}
                  </Typography>
                </Box>
                <img
                  src={
                    article.logo ||
                    'https://miro.medium.com/v2/resize:fit:679/1*yuVzMhCJyDENbyhwAsrkwA.png'
                  }
                  style={{
                    width: '100%',
                    height: '',
                    objectFit: 'cover',
                    aspectRatio: '2 / 1',
                    display: 'flex',
                  }}
                  alt={article.title}
                />
                <Typography
                  className={textClasses.baseBold}
                  align='center'
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    marginTop: 8,
                  }}
                >
                  {article.title}
                </Typography>
                <Box height='16px'>
                  <Typography className={textClasses.xsRegular}>
                    {article.author ? `Author: ${article.author}` : ''}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='flex-end' marginTop='20px'>
                  <Button
                    href={article.link}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      backgroundColor:
                        article.type === 'medium' ? '#2780aa' : 'salmon',
                      color: 'white',
                    }}
                  >
                    Read the article
                  </Button>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </UnauthorizedContainWrapper>
  );
}
